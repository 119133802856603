'use client';

import { useState } from 'react';
import NextImage, {
  type ImageProps as NextImageProps,
  type ImageLoader,
  type ImageLoaderProps,
} from 'next/image';
import { checkIfSrcExistsInProviders, generateOptimizedUrl } from '../../utils/imageUtils';
import type { PrismicImage } from '@virginexperiencedays/cms';
import { cn } from '@virginexperiencedays/tailwind';

const FALLBACK_IMAGE =
  'https://images.prismic.io/virginexperiencedays/6288bdd2-0da0-4db1-bc60-1403f1840a19_gray.jpeg?auto=compress,format';

export type ImageProps = NextImageProps & {
  className?: string;
  imageClassName?: string; // styles directly for the img tag
  /**
   * A Prismic image object
   */
  imageObject?: PrismicImage;
  /**
   * A `data-testid` attribute to pass to the image container.
   * - Note this is an attribute on the containing `<div />`, and is not passed to the Next `<Image />` component
   */
  dataTestId?: string;
  // set to false if wish to use default nextjs loader
  withLoader?: boolean;
};

// New image docs
// https://nextjs.org/docs/pages/api-reference/components/image

export const Image = ({
  className,
  imageObject,
  dataTestId,
  quality = 80,
  src = FALLBACK_IMAGE,
  fill,
  imageClassName,
  style,
  withLoader = true,
  ...rest
}: ImageProps) => {
  const [error, setError] = useState(null);

  const loader = ({ src, width, quality }: ImageLoaderProps) => {
    return checkIfSrcExistsInProviders(src)
      ? generateOptimizedUrl({ src, width, quality, imageObject })
      : src;
  };

  return (
    <div
      style={style}
      className={cn(fill && 'relative overflow-hidden', 'h-auto w-full', className)}
      data-testid={dataTestId}
      data-priority={rest?.priority}
    >
      <NextImage
        className={cn(
          fill && 'object-cover', // this can be overwritten per need, assigining default value as that's what is mostly used right now
          imageClassName
        )}
        fill={fill}
        onError={setError}
        quality={quality}
        src={error ? FALLBACK_IMAGE : src}
        {...rest}
        loader={withLoader ? (loader as ImageLoader) : undefined}
      />
    </div>
  );
};
