import type { PrismicImage } from '@virginexperiencedays/cms';

export const imgixProviders: string[] = [
  // Extend providers here when introducing new ones (e.g. cloudinary, etc.)
  'https://images.prismic.io/',
  'https://virginexperiencedaysdev.imgix.net/',
  'https://images.virginexperiencedays.co.uk/',
  'https://vedcdn.imgix.net',
  // The below are not Imgix providers but used to remove warnings in Footer component
  'https://ved.virgin.authxlab.com',
  'https://www.virginexperiencedays.co.uk',
];

export const checkIfSrcExistsInProviders = (src: string): boolean => {
  if (typeof src !== 'string') return false;
  for (const imgixProvider of imgixProviders) if (src?.includes(imgixProvider)) return true;
  return false;
};

type GenerateOptimizedUrlParams = {
  src: string;
  width: number;
  quality: number;
  imageObject?: PrismicImage;
};

export const generateOptimizedUrl = (args: GenerateOptimizedUrlParams): string => {
  const { src: imgSrc, width, quality, imageObject } = args;

  try {
    if (!imgSrc && !imageObject?.url) {
      throw new Error('src and imageObject.url are not defined');
    }
    if (imageObject?.url && (!imageObject?.dimensions?.width || !imageObject?.dimensions?.height)) {
      throw new Error('imageObject.dimensions.(width | height) are not defined');
    }

    if (imageObject?.url) {
      const baseUrl = removeQueryParamsFromUrl(imageObject.url);
      return addQueryParamsToUrl(baseUrl, {
        width: imageObject.dimensions.width,
        quality,
      });
    } else {
      const baseUrl = removeQueryParamsFromUrl(imgSrc);
      return addQueryParamsToUrl(baseUrl, {
        width,
        quality,
      });
    }
  } catch (error) {
    console.error(`[Image]: ${imgSrc} - ${error}`);
    return imgSrc;
  }
};

export const removeQueryParamsFromUrl = (url: string): string => {
  if (!url) throw new Error('URL is not defined');

  const urlObject = new URL(url);
  // params to retain
  const rect = urlObject.searchParams.get('rect');

  urlObject.search = ''; // remove query params
  urlObject.hash = ''; // remove hash fragment

  // put back retained params
  if (rect) urlObject.searchParams.set('rect', rect);

  return urlObject.toString();
};

export function addQueryParamsToUrl(
  url: string,
  queryParams: Omit<GenerateOptimizedUrlParams, 'src'>
): string {
  if (!url) throw new Error('URL is not defined');

  const urlObject = new URL(url);
  const { width, quality } = queryParams;

  urlObject.searchParams.set('auto', 'compress,format');
  if (typeof width === 'number') {
    urlObject.searchParams.set('w', width.toString());
  }

  if (typeof quality === 'number') {
    urlObject.searchParams.set('q', quality.toString());
  }

  urlObject.searchParams.set('fit', 'max');

  return urlObject.toString();
}
