import useFeatureFlags from '@virginexperiencedays/feature-flags';
import GTM from '../components/tracking/gtm';

export const initializeGtm = () => {
  const [isEnabled] = useFeatureFlags();

  if (!isEnabled('FF_gtm')) return;

  const gtm = new GTM(
    process.env.NEXT_PUBLIC_GTM_ID,
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
  );

  gtm.initialize();
};
