import { cn } from '@virginexperiencedays/tailwind';

export const choiceLabelSpanStyles = cn(
  // base
  'flex cursor-pointer justify-center rounded border-2 p-2 text-center text-sm font-semibold leading-none overflow-wrap-anywhere',
  // bg & border & hover
  'bg-background-primary-faded border-border-primary-faded text-neutral',
  'hover:bg-background-primary-faded-hover hover:border-background-primary-faded-hover',
  // input checked
  'peer-checked:bg-background-primary peer-checked:border-background-primary peer-checked:text-white',
  // input disabled
  'peer-disabled:bg-background-disabled peer-disabled:border-background-disabled peer-disabled:text-neutral-faded peer-disabled:cursor-not-allowed',
  // input focus
  'peer-focus:border-border-neutral'
);

// ensure label has relative positioning
export const choiceLabelInputStyles = 'peer absolute left-0 top-0 -z-[1] opacity-0';
