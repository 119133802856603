import { type ReactNode, type ReactElement, type SVGProps, cloneElement } from 'react';

import IconBlackFriday from './IconBlackFriday';
import IconBriefcase from './IconBriefcase';
import IconBurger from './IconBurger';
import IconCalendar from './IconCalendar';
import IconCamera from './IconCamera';
import IconCart from './IconCart';
import IconMobileChair from './IconChair';
import IconChairSmall from './IconChairSmall';
import IconChevron from './IconChevron';
import IconMobileCoach from './IconCoach';
import IconCoachSmall from './IconCoachSmall';
import IconMobileCocktail from './IconCocktail';
import IconCocktailSmall from './IconCocktailSmall';
import IconMobileCompass from './IconCompass';
import IconCompassSmall from './IconCompassSmall';
import IconCross from './IconCross';
import IconEllipse from './IconEllipse';
import IconFavourite from './IconFavourite';
import IconMobileFootball from './IconFootball';
import IconFootballSmall from './IconFootballSmall';
import IconGift from './IconGift';
import IconGiftCard from './IconGiftCard';
import IconGiftFinder from './IconGiftFinder';
import IconGiftSmall from './IconGiftSmall';
import IconHeart from './IconHeart';
import IconHeartSmall from './IconHeartSmall';
import IconHelmetSmall from './IconHelmetSmall';
import IconLightning from './IconLightning';
import IconLocationPin from './IconLocationPin';
import IconLocationPinSmall from './IconLocationPinSmall';
import IconMobileLotusFlower from './IconLotusFlower';
import IconLotusFlowerSmall from './IconLotusFlowerSmall';
import IconMultiChoice from './IconMultiChoice';
import IconMobilePaintBrush from './IconPaintBrush';
import IconPaintbrushSmall from './IconPaintbrushSmall';
import IconMobilePaw from './IconPaw';
import IconPawSmall from './IconPawSmall';
import IconPhoto from './IconPhoto';
import IconMobilePlane from './IconPlane';
import IconPlaneSmall from './IconPlaneSmall';
import IconMobileSailboat from './IconSailboat';
import IconSailboatSmall from './IconSailboatSmall';
import IconSaleTag from './IconSaleTag';
import IconSearch from './IconSearch';
import IconSocial from './IconSocial';
import IconSparkle from './IconSparkle';
import IconStar from './IconStar';
import IconMobileTeaCup from './IconTeaCup';
import IconTeacupSmall from './IconTeacupSmall';
import IconMobileTent from './IconTent';
import IconTentSmall from './IconTentSmall';
import IconMobileTicket from './IconTicket';
import IconTicketSmall from './IconTicketSmall';
import IconUser from './IconUser';
import IconUserSmall from './IconUserSmall';
import IconMobileCamera from './mobile/IconMobileCamera';
import IconMobileMultiChoice from './mobile/IconMobileMultiChoice';
import RoundedSquareSvg from './RoundedSquareSvg';

import type { ThemeType } from '../../../types';

const desktopIcons = new Map<string, ReactNode>([
  ['camera', <IconCamera strokeWidth="2.2" />],
  ['chair', <IconChairSmall />],
  ['coach', <IconCoachSmall />],
  ['cocktail', <IconCocktailSmall />],
  ['compass', <IconCompassSmall />],
  ['football', <IconFootballSmall />],
  ['gift', <IconGiftSmall />],
  ['gift-card', <IconGiftCard />],
  ['gift-finder', <IconGiftFinder iconClass="h-5 w-5" />],
  ['heart', <IconHeartSmall />],
  ['helmet', <IconHelmetSmall />],
  ['location-pin', <IconLocationPinSmall />],
  ['lotus-flower', <IconLotusFlowerSmall />],
  ['paintbrush', <IconPaintbrushSmall />],
  ['paw', <IconPawSmall />],
  ['plane', <IconPlaneSmall />],
  ['sail-boat', <IconSailboatSmall />],
  ['teacup', <IconTeacupSmall />],
  ['tent', <IconTentSmall />],
  ['ticket', <IconTicketSmall />],
  ['user', <IconUser />],
  ['rounded-square', <RoundedSquareSvg />],
  ['ellipse', <IconEllipse />],
  ['black-friday', <IconBlackFriday />],
  ['favourite', <IconFavourite />],
  ['multi-choice', <IconMultiChoice strokeWidth="2" />],
]);

const mobileIcons = new Map<string, ReactNode>([
  ['briefcase', <IconBriefcase />],
  ['burger', <IconBurger />],
  ['calendar', <IconCalendar />],
  ['camera', <IconMobileCamera strokeWidth="1" />],
  ['cart', <IconCart />],
  ['chair', <IconMobileChair strokeWidth="0.3" />],
  ['chevron-down', <IconChevron />],
  ['chevron-left', <IconChevron className="rotate-90" />],
  ['chevron-right', <IconChevron className="-rotate-90" />],
  ['chevron-up', <IconChevron className="-rotate-180" />],
  ['coach', <IconMobileCoach strokeWidth="0.2" />],
  ['cocktail', <IconMobileCocktail />],
  ['compass', <IconMobileCompass />],
  ['cross', <IconCross />],
  ['facebook', <IconSocial type="facebook" />],
  ['football', <IconMobileFootball />],
  ['gift', <IconGift />],
  ['gift-card', <IconGiftCard />],
  ['gift-finder', <IconGiftFinder iconClass="h-5 w-5" />],
  ['heart', <IconHeart />],
  ['helmet', <IconHelmetSmall strokeWidth="0.1" />],
  ['instagram', <IconSocial type="instagram" />],
  ['location-pin', <IconLocationPin />],
  ['lotus-flower', <IconMobileLotusFlower />],
  ['paintbrush', <IconMobilePaintBrush />],
  ['paw', <IconMobilePaw />],
  ['photo', <IconPhoto />],
  ['plane', <IconMobilePlane />],
  ['sail-boat', <IconMobileSailboat />],
  ['sale-tag', <IconSaleTag />],
  ['search', <IconSearch />],
  ['shock', <IconLightning />],
  ['sparkle', <IconSparkle />],
  ['star', <IconStar />],
  ['teacup', <IconMobileTeaCup />],
  ['tent', <IconMobileTent strokeWidth="0.3" />],
  ['ticket', <IconMobileTicket />],
  ['twitter', <IconSocial type="twitter" />],
  ['user', <IconUserSmall />],
  ['youtube', <IconSocial type="youtube" />],
  ['multi-choice', <IconMobileMultiChoice strokeWidth="0.9" />],
]);

type Props = {
  name: string;
  stroke?: string;
  fill?: string;
  theme?: ThemeType;
  themeColor?: string;
  device?: 'desktop' | 'mobile';
  shadedColor?: string;
  emptyColor?: string;
} & SVGProps<SVGSVGElement>;

const RenderIcon = ({ name, device = 'mobile', ...props }: Props) => {
  let icon: ReactNode | null = null;

  // return the icon based on the device or fallback to the other device if not found
  if (device === 'desktop') {
    icon = desktopIcons.get(name) ?? mobileIcons.get(name);
  } else if (device === 'mobile') {
    icon = mobileIcons.get(name) ?? desktopIcons.get(name);
  }

  return icon ? cloneElement(icon as ReactElement, props) : <IconPhoto {...props} />;
};

export default RenderIcon;
