type FlagsMap = Record<string, number>;

/*
- all flags need to be listed here in order for 
  next to allow use them in browser with `NEXT_PUBLIC_` prefix.
- `_FF_` is used to distinguish flags from other variables
- here we can setup default variables for flags
*/

const flags: FlagsMap = {
  FF_test: parseInt(process.env.NEXT_PUBLIC_FF_TEST) || 0,
  FF_new_filters: parseInt(process.env.NEXT_PUBLIC_FF_NEW_FILTERS) || 0,
  FF_fonts: parseInt(process.env.NEXT_PUBLIC_FF_FONTS) || 1,
  FF_gtm: parseInt(process.env.NEXT_PUBLIC_FF_GTM) || 0,
  FF_fb_capi: parseInt(process.env.NEXT_PUBLIC_FF_FB_CAPI) || 0,
  FF_fb_pixel: parseInt(process.env.NEXT_PUBLIC_FF_FB_PIXEL) || 0,
  FF_sentry: parseInt(process.env.NEXT_PUBLIC_SENTRY) || 0,
  FF_limited_deployments: parseInt(process.env.FF_LIMITED_DEPLOYMENTS) || 0,
  FF_ab_tests: parseInt(process.env.FF_AB_TESTS) || 0,
  FF_header_2024: parseInt(process.env.NEXT_PUBLIC_FF_HEADER_2024) || 0,
  FF_product_cards_v2: parseInt(process.env.NEXT_PUBLIC_FF_PRODUCT_CARDS_V2) || 0,
  FF_cms_cache: parseInt(process.env.NEXT_PUBLIC_FF_CMS_CACHE) || 1,
  FF_featured_column_fallback:
    parseInt(process.env.NEXT_PUBLIC_FF_NAV_FEATURED_COLUMN_FALLBACK) || 0,
  FF_new_product_api: parseInt(process.env.FF_NEW_PRODUCT_API) || 0,
  // Promotions
  FF_christmas: parseInt(process.env.NEXT_PUBLIC_FF_CHRISTMAS) || 0,
  FF_black_friday: parseInt(process.env.NEXT_PUBLIC_FF_BLACK_FRIDAY) || 0,
  FF_cyber_monday: parseInt(process.env.NEXT_PUBLIC_FF_CYBER_MONDAY) || 0,
  FF_mothers_day: parseInt(process.env.NEXT_PUBLIC_FF_MOTHERS_DAY) || 0,
  FF_fathers_day: parseInt(process.env.NEXT_PUBLIC_FF_FATHERS_DAY) || 0,
  FF_easter: parseInt(process.env.NEXT_PUBLIC_FF_EASTER) || 0,
  FF_valentines: parseInt(process.env.NEXT_PUBLIC_FF_VALENTINES) || 0,
  FF_new_pdp: parseInt(process.env.NEXT_PUBLIC_FF_NEW_PDP) || 0,
};

export default flags;
