import { cn } from '@virginexperiencedays/tailwind';

import { Button } from '../../layout/Button';
import { BodyText } from '../../typography/BodyText';

export type CookiesModalProps = {
  show: boolean;
  className?: string;
  onClick: () => void;
  href?: string;
};

export const CookiesModal = ({
  className,
  onClick,
  show,
  href = 'https://www.virginexperiencedays.co.uk/cookies-are-our-friends', //@TODO: Default value should be exctracted into a constant per app. Leaving here for backwards compatibility
}: CookiesModalProps) => {
  return (
    show && (
      <div
        className={cn(
          'border-border-neutral-faded fixed bottom-[40px] left-1/2 z-[700] m-auto w-[95%] max-w-[954px] -translate-x-1/2 rounded-lg border bg-white p-4 shadow-lg ',
          'md:bottom-[56px] md:flex md:items-center md:px-6 md:py-4',
          className
        )}
      >
        <div>
          <BodyText className="text-neutral" size="small">
            We use cookies to give you the best experience on Virgin Experience Days, and to make
            sure the Virgin Experience Days ads you see on other sites are relevant. By continuing,
            you agree to our use of cookies – find out more{' '}
            <a className="text-neutral hover:text-link underline" href={href}>
              here.
            </a>
          </BodyText>
        </div>
        <Button className="text-neutral mt-4 md:ml-12 md:mt-0" onClick={onClick}>
          Accept
        </Button>
      </div>
    )
  );
};
