// TODO do we not have this defined anywhere else?
type Product = {
  locations?: { name: string }[];
  price?: { rrp: number; displayPrice: number };
};

const getLocationInfo = (product: Product): number | string | null => {
  if (!Array.isArray(product?.locations) || !product?.locations?.length) return null;

  const locations = product.locations;

  if (locations.length > 1) return locations.length;
  if (locations[0]?.name) return product.locations[0].name;

  return null;
};

const getPriceInfo = (
  product: Product
): { currentPrice: number; pastPrice: number | null; badge: string | null } | null => {
  // TODO what do we do if there is no currentPrice and/or rrp?
  const currentPrice = product?.price?.displayPrice ?? null;
  const rrp = product?.price?.rrp ?? null;

  if (!currentPrice || !rrp) return null;

  const pastPrice = rrp > currentPrice ? product?.price?.rrp ?? null : null;
  const badge = pastPrice && pastPrice > currentPrice ? 'SALE' : null;

  return { currentPrice, pastPrice, badge };
};

export { getLocationInfo, getPriceInfo };
